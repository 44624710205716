import React, { useContext } from 'react';
import { WiEarthquake } from 'react-icons/wi';
import dayjs from 'dayjs';

// CONTEXT
import { FaCalendarCheck } from 'react-icons/fa';
import { asInt, formatDate, formatTime, with1Decimal } from '../../shared/format';
import { hasMecFocal } from '../../shared/utils';

const QuakeItem = ({ quake, isSelected, onSelect }) => {
    const handleShowInfo = () => {
        onSelect(quake);
    };

    const currentTime = dayjs(new Date());
    const _quakeDate = dayjs(quake.date);

    let colorQuake = '';
    if (currentTime.diff(_quakeDate, 'hours') <= 24) colorQuake = 'bg-red-500';
    if (
        currentTime.diff(_quakeDate, 'hours') >= 25 &&
        currentTime.diff(_quakeDate, 'hours') <= 48
    )
        colorQuake = 'bg-amber-500';
    if (currentTime.diff(_quakeDate, 'hours') >= 49)
        colorQuake = 'bg-yellow-500';

    return (
        <tr
            id={`quakeItem_${quake.id}`}
            onClick={handleShowInfo}
            className={`quakeItem ${
                isSelected && 'bg-gray-300 odd:bg-gray-300'
            }  cursor-pointer bg-white text-xs odd:bg-slate-100 hover:bg-slate-200`}
        >
            <td
                className="h-10 pl-4"
                title={
                    'Magnitud: ' + with1Decimal(quake.magnitude) + ' ' + quake.unit
                }
            >
                <div className="flex items-center">
                    <div className="quakeIcon mr-2">
                        <WiEarthquake className="bg-yellow-500 text-xl text-white" />
                    </div>
                    <div className="quake.magnitude mr-3 flex items-center text-lg font-bold text-brand-brown">
                        {with1Decimal(quake.magnitude)}{' '}
                        <sub className="ml-1 mr-2 text-xs">
                            {quake.unit}
                        </sub>
                    </div>
                </div>
            </td>
            <td
                className="quake.depth  border-r border-gray-100"
                title={'Profundidad: ' + asInt(quake.depth) + 'Kms'}
            >
                {asInt(quake.depth)} Kms
            </td>
            <td
                className="quake.date item-center flex flex w-6/20 items-center justify-start"
                title={
                    'Fecha: ' +
                    formatDate(quake.datetime) +
                    ' ' +
                    formatTime(quake.datetime)
                }
            >
                <FaCalendarCheck className="mr-2 text-base text-gray-500" />
                <span className="flex flex-col">
                            <span className="text-xs uppercase">
                                {formatTime(quake.datetime)}
                            </span>
                            <span className="text-xs font-bold">
                                {formatDate(quake.datetime)}
                            </span>
                        </span>
            </td>
            <td className="">
                {hasMecFocal(quake) ?
                    <img src={quake.beachball_url} title="Mecanismo Focal" className="rounded-full w-8 h-8" /> :
                    <div title="Sin Mecanismo Focal"
                         className="rounded-full w-8 h-8" />
                    // <div title="Sin Mecanismo Focal" className="rounded-full w-8 h-8 border border-solid border-gray-400" />
                }
            </td>
        </tr>
    );
};

export default QuakeItem;
