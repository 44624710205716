/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import './HomeMap';
import './QuakesArchive';
import './BlogPost';

// HANDLE SHOWSEARCH
// const searchBtn = document.getElementById('search-btn');
// const searchForm = document.querySelector('.search-form');

// searchBtn.addEventListener('click', () => {
//     if (searchForm.classList.contains('top-[-100%]')) {
//         searchForm.classList.remove('top-[-100%]');
//         searchForm.classList.add('top-[12%]');
//     } else {
//         searchForm.classList.add('top-[-100%]');
//         searchForm.classList.remove('top-[12%]');
//     }
// });

// TOGGLE MENU
document.getElementById('nav-toggle').onclick = function() {
    document.getElementById('mobile-menu').classList.toggle('hidden');
};
