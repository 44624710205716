import ReactDOM from 'react-dom';
import QuakeList from '../Panel/modules/quakes/pages/QuakeList';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';

const QuakesArchive = () => {
    return (
        <RecoilRoot>
            <BrowserRouter>
                <QuakeList withTitle={false} />
            </BrowserRouter>
        </RecoilRoot>
    );
};

export default QuakesArchive;

if (document.getElementById('quakes-archive')) {
    ReactDOM.render(
        <QuakesArchive />
        , document.getElementById('quakes-archive'));
}
