import React, { useState, useEffect, useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import GoogleMapReact from 'google-map-react';
import axios from 'axios';

// CONTEXT
import InfoMarkerContextProvider from './Context/InfoMarkerContext';
import { InfoMarkerContext } from './Context/InfoMarkerContext';

// STYLES
import { blueSeaMapsTheme } from '../shared/mapsThemes';

// COMPONENTS
import QuakeMarker from '../Panel/modules/quakes/components/QuakeMarker';
import QuakesSidebar from './components/QuakesSidebar';

const HomeMap = () => {
    const API_KEY = 'AIzaSyDvODQ6evWYygh_7WNLBYHCHE-J6rTBfIU';
    const [quakes, setQuakes] = useState([]);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [widthMap, setWidthMap] = useState('');
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const { selectedItem, setSelectedItem, mapProps, setMapProps } =
        useContext(InfoMarkerContext);

    useEffect(() => {
        resetMapDefaults();
    }, [sidebarOpen]);

    const resetMapDefaults = () => {
        if (sidebarOpen && windowSize.innerWidth > 1280) {
            setMapProps({ center: { lat: 18.9576657, lng: -72.157 }, zoom: 8 });
        } else {
            setMapProps({
                center: { lat: 18.9020282, lng: -70.933166 },
                zoom: 8,
            });
        }
    };

    useEffect(() => {
        getQuakes();

        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    const getQuakes = () => {
        axios.get('/sanctum/csrf-cookie').then(() => {
            axios
                .get('/api/quakes?limit=20')
                .then((res) => {
                    const quakesData = res.data.data;
                    // items should be reversed otherwise will render in the wrong order
                    setQuakes(quakesData);
                })
                .catch((err) => {
                    console.error('Imposible cargar los eventos sismicos');
                });
        });
    };

    const handleMarkerSelection = (quake) => {
        if (quake) {
            setMapProps({
                zoom: 9,
                center: { lat: quake.latitude, lng: quake.longitude },
            });
            setSelectedItem(quake);
            document.querySelector(`#quakeItem_${quake.id}`)?.scrollIntoView({
                block: 'nearest',
                behavior: 'smooth',
            });
        } else {
            setSelectedItem(null);
            resetMapDefaults();
        }
    };

    return (
        <>
            <div className="map-container relative z-20 flex w-full">
                <QuakesSidebar
                    stylesSidebar={`quakes ${
                        sidebarOpen ? 'left-0' : '-left-[25%]'
                    } w-[420px] h-full flex-wrap bg-white border-solid border-y border-gray-300 hidden lg:flex relative z-50 transition-all`}
                    onSidebarToggle={setSidebarOpen}
                    sidebarOpen={sidebarOpen}
                    quakesData={quakes}
                    onItemSelected={handleMarkerSelection}
                    selectedItem={selectedItem}
                />
                <div
                    className={` ${
                        sidebarOpen ? '' : ''
                    } mapElement flex flex-1 transition-all duration-300`}
                    style={{
                        height: '100%',
                        width: sidebarOpen ? widthMap : '100%',
                    }}
                >
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: API_KEY }}
                        center={mapProps.center}
                        zoom={mapProps.zoom}
                        options={{ styles: blueSeaMapsTheme }}
                        style={{
                            height: '80vh',
                            width: sidebarOpen ? widthMap : '100%',
                        }}
                        // className={`h-[60vh] lg:h-[90vh] ${sidebarOpen ? widthMap : '100%'}`}
                    >
                        {[...quakes].reverse().map((quake) => (
                            <QuakeMarker
                                selected={selectedItem?.id === quake.id}
                                quake={quake}
                                key={quake.id}
                                lat={quake.latitude}
                                lng={quake.longitude}
                                onActivate={handleMarkerSelection}
                            />
                        ))}
                    </GoogleMapReact>
                </div>
            </div>
            <QuakesSidebar
                stylesSidebar="flex flex-wrap lg:hidden py-10"
                onSidebarToggle={true}
                sidebarOpen={true}
                quakesData={quakes}
                onItemSelected={handleMarkerSelection}
                selectedItem={selectedItem}
            />
        </>
    );
};
export default HomeMap;

if (document.getElementById('home-map')) {
    ReactDOM.render(
        <InfoMarkerContextProvider>
            <HomeMap />
        </InfoMarkerContextProvider>,
        document.getElementById('home-map')
    );
}
