import ReactDOM from 'react-dom';
import PostImageModal from './components/PostImageModal';

const BlogPostImages = ({ src, alt }) => {

    return <div>
        <PostImageModal alt={alt} src={src} />
    </div>;
};

const elements = document.querySelectorAll('article img');
if (elements.length > 0) {
    elements.forEach((el) => {
        const props = {
            alt: el.alt,
            src: el.src
        };
        const container = document.createElement('div')
        el.replaceWith(container)
        ReactDOM.render(<BlogPostImages {...props} />, container);
    });
}
