import React, { createContext, useState } from 'react';

export const InfoMarkerContext = createContext(null);

export default function InfoMarkerContextProvider({ children }) {
    const [mapProps, setMapProps] = useState({
        center: { lat: 18.9576657, lng: -72.157 },
        zoom: 8
    });
    const [selectedItem, setSelectedItem] = useState(null);

    return (
        <InfoMarkerContext.Provider value={{ selectedItem, setSelectedItem, mapProps, setMapProps }}>
            {children}
        </InfoMarkerContext.Provider>
    );
}
