import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

export default function PostImageModal({ src, alt }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <div className="relative group cursor-pointer" onClick={() => setOpen(true)}>
                <img src={src} alt={alt} />
                <div className="opacity-0 absolute text-sm px-1 py-2 bottom-0 right-0 bg-gray-200 transition-opacity group-hover:opacity-100">Haga clic en la imagen para agrandar</div>
            </div>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as='div' className='relative z-10' onClose={() => setOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>

                    <div className='fixed z-10 inset-0 overflow-y-auto'>
                        <div
                            className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
                            <Transition.Child
                                as={Fragment}
                                enter='ease-out duration-300'
                                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                                enterTo='opacity-100 translate-y-0 sm:scale-100'
                                leave='ease-in duration-200'
                                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            >
                                <Dialog.Panel
                                    className=''>
                                    <div className='relative'>
                                        <img onClick={() => setOpen(false)} className='cursor-pointer max-w-95 max-h-95'
                                             src={src} alt={alt} />
                                        <div
                                            className='absolute bottom-4 right-0 left-0 '>
                                            <div className="max-w-screen-xl mx-auto px-2 py-3  text-center italic text-md bg-gray-100 rounded-sm border border-solid border-gray-200 text-brand-brown">
                                                {alt}
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
