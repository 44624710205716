import React, { useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';

// COMPONENTS
import QuakeItem from './QuakeItem';

const QuakesSidebar = (props) => {
    const { onSidebarToggle, sidebarOpen, stylesSidebar, selectedItem, onItemSelected } = props;

    const showSidebar = () => onSidebarToggle(!sidebarOpen);

    const quakes = props.quakesData;

    return (
        <>
            <div className={stylesSidebar}>
                <a
                    className="handlerSidebar w-14 h-14 flex justify-center items-center cursor-pointer bg-brand-brown text-white text-8 absolute -right-14 top-0"
                    onClick={showSidebar}
                >
                    <FaChevronRight className={`${sidebarOpen ? 'rotate-180' : 'rotate-0'}`} />
                </a>
                <div className="sidebarHeader w-full flex items-center bg-brand-blue text-xl py-2 pl-4   text-white">
                    Sismos recientes
                </div>

                <table className="w-full text-left px-4 overflow-y-scroll" style={{ height: 'calc(100% - 60px)' }}>
                    <thead className="w-full">
                    <tr>
                        <th className="w-5/20 py-2 pl-4 text-[.7rem] font-medium uppercase" title="Magnitud">
                            Magnitud
                        </th>
                        <th className="w-5/20 text-[.7rem] font-medium text-ellipsis uppercase" title="Profundidad">
                            Profundidad
                        </th>
                        <th className="w-6/20 text-[.7rem] font-medium uppercase" title="Fecha Local">
                            Fecha<sup>(Local)</sup>
                        </th>
                        <th className="w-4/20 text-[.7rem] font-medium uppercase" title="Mecanismo Focal">
                            Mec. Focal
                        </th>
                    </tr>
                    </thead>
                    <tbody className="w-full  ">
                    {
                        quakes.map(quake => (
                            <QuakeItem
                                key={quake.id}
                                quake={quake}
                                onSelect={onItemSelected}
                                isSelected={quake.id === selectedItem?.id}
                            />
                        ))
                    }

                    </tbody>
                </table>
            </div>
        </>
    );
};

export default QuakesSidebar;
